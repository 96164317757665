import { Action } from '@ngrx/store';
import { type } from '../../../util';
import { II9Info } from '../../../core/services/i-9-form/interfaces';

export const ActionTypes = {
  FETCH_INFO: type('[I9 From] fetch info'),
  STORE_INFO: type('[I9 From] store info'),
  CHECK_I9_ERROR_PROCESSING: type('[I9 From] check error processing'),
  REINITIATE_I9: type('[I9 From] check reinitiate'),
};

export class FetchI9Info implements Action {
  public type = ActionTypes.FETCH_INFO;

  constructor(public payload?: any) {}
}

export class StoreI9Info implements Action {
  public type = ActionTypes.STORE_INFO;

  constructor(public payload: II9Info) {}
}

export class CheckI9ErrorProcessing implements Action {
  public type = ActionTypes.CHECK_I9_ERROR_PROCESSING;

  constructor(public payload?: any) {}
}

export class ReinitiateI9 implements Action {
  public type = ActionTypes.REINITIATE_I9;

  constructor(public payload?: any) {}
}

export type TypeAction =
  FetchI9Info |
  StoreI9Info ;
