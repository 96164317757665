export enum I9StatusesEnum {
  I9NotNeeded = 1,
  Required = 2,
  PendingSection1 = 3,
  PendingSection2 = 4,
  CompletedSection2 = 5,
  SubmittedToEVerify = 6,
  EVerifyAuthorized = 7,
  EVerifyNotAuthorized = 8,
  PendingReview = 9,
  PendingSection1Revision = 10,
  PendingSection2Revision = 11,
  ProcessManually = 12,
  Expired = 13
}

export interface II9Info {
  text: string | null;
  status: I9StatusesEnum | null;
  sectionOneUrl: string | null;
}
