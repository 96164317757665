import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractServerInteractionService } from '../abstract/abstract.server.interaction.service';
import { Observable } from 'rxjs';
import { II9Info } from './interfaces';
import { AppService } from '../../../../app.service';

@Injectable()
export class I9FormService extends AbstractServerInteractionService {
  protected get url(): {
    info: string,
    reinitiate: string,
  } {
    return this.appService.getApiUrls().i9;
  }

  constructor(protected appService: AppService,
              protected http: HttpClient) {
    super();
  }

  public fetchInfo(): Observable<II9Info | any> {
    return this.http.get(this.url.info);
  }

  public reinitiate() {
    return this.http.post(this.url.reinitiate, null);
  }
}
