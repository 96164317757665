import { createSelector } from '@ngrx/store';
import { ActionTypes, TypeAction } from './i-9-form.actions';
import { IRootState } from '../../../store';
import { I9StatusesEnum, II9Info } from '../../../core/services/i-9-form/interfaces';

export interface IState extends II9Info {
}

const initialState: IState = {
  status: null,
  text: null,
  sectionOneUrl: null,
};

export function i9FormReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.STORE_INFO: {
      return Object.assign({}, state, payload);
    }
    default: {
      return state;
    }
  }
}

export const getI9StatusText = createSelector(
  ({ i9Form }: IRootState): IState => i9Form,
  ({ text }: IState): string | null => text
);

export const getI9Status = createSelector(
  ({ i9Form }: IRootState): IState => i9Form,
  ({ status }: IState): number | null => status
);

export const getSectionOneUrl = createSelector(
  ({ i9Form }: IRootState): IState => i9Form,
  ({ sectionOneUrl }: IState): string | null => sectionOneUrl
);

export const getIsPendingSection1Revision = createSelector(
  ({ i9Form }: IRootState): IState => i9Form,
  ({ status }: IState): boolean => status === I9StatusesEnum.PendingSection1Revision
);

export const getIsProcessManually = createSelector(
  ({ i9Form }: IRootState): IState => i9Form,
  ({ status }: IState): boolean => status === I9StatusesEnum.ProcessManually
);

export const getIsI9Expired = createSelector(
  ({ i9Form }: IRootState): IState => i9Form,
  ({ status }: IState): boolean => status === I9StatusesEnum.Expired
);
